<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import useCarriers from "../../../hooks/useCarriers.js";
import { setPageTitle } from "@/utils/pageTitle";
import CarrierLayout from "@/layouts/CarrierLayout.vue";
import { useUserStore } from "@/stores/user";

const route = useRoute();
const updatedCarrier = ref({
  slug: "",
  name: "",
  carrier_type: "",
  service_code: "",
  alt_code: "",
});
const allowEdit = ref(false);
const userStore = useUserStore();

const { fetchCarrier, carrier, updateCarrier } = useCarriers();

onMounted(async () => {
  await fetchCarrier(route.params.id);
  setPageTitle(`Carrier // ${carrier.value.id}`);
});

const save = async () => {
  Object.keys(updatedCarrier.value).forEach((key) => {
    updatedCarrier.value[key] = carrier.value[key];
  });

  await updateCarrier(route.params.id, updatedCarrier.value);
}

</script>

<template>
  <CarrierLayout v-if="carrier" :carrier="carrier" title="Details">
    <v-container>
      <label>UNIQUE IDENTIFIER</label>
      <v-text-field 
        class="mb-4"
        v-model="carrier.slug"
        variant="outlined"
        hide-details="auto"
        density="compact"
        readonly
        disabled
      />

      <label>CARRIER NAME</label>
      <v-text-field 
        class="mb-4"
        v-model="carrier.name"
        variant="outlined"
        hide-details="auto"
        density="compact"
        :disabled="!allowEdit"
      />

      <label>CARRIER TYPE</label>
      <v-select 
        class="mb-4"
        v-model="carrier.carrier_type"
        :items="['freight', 'parcel']"
        variant="outlined"
        hide-details="auto"
        density="compact"
        :disabled="!allowEdit"
      />

      <label>CARRIER CODE</label>
      <v-text-field 
        class="mb-4"
        v-model="carrier.service_code"
        variant="outlined"
        hide-details="auto"
        density="compact"
        :disabled="!allowEdit"
      />

      <label>ALT. CARRIER CODE</label>
      <v-text-field 
        class="mb-4"
        v-model="carrier.alt_code"
        variant="outlined"
        hide-details="auto"
        density="compact"
        :disabled="!allowEdit"
      />

      <div class="d-flex justify-end" v-if="userStore.user.permissions.update_carrier">
        <v-btn 
          v-if="allowEdit"
          class="mr-4"
          density="compact" 
          color="red" 
          variant="outlined" 
          @click="() => (allowEdit = false)"
        >Cancel</v-btn>
        <v-btn 
          v-if="allowEdit"
          density="compact" 
          color="success" 
          variant="flat" 
          @click="save"
        >Save</v-btn>
        <v-btn 
          v-if="!allowEdit && userStore.user.permissions.update_carrier"
          density="compact" 
          color="primary" 
          variant="flat" 
          @click="() => (allowEdit = true)"
        >Edit</v-btn>
      </div>
    </v-container>
  </CarrierLayout>
</template>